// react
import { useParams, Link, useLocation } from "react-router-dom";
import Markdown from "react-markdown";

import image from "./image1.webp";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartPie, faChevronRight } from "@fortawesome/free-solid-svg-icons";

// global components
import Loading from "../../components/Loading/Loading";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";
import RefreshButton from "../../components/RefreshButton/RefreshButton";
import ProjectInfoHeader from "../../components/ProjectInfoHeader/ProjectInfoHeader";

// local components
import AnnualForecast from "./Visualizations/AnnualForecast/AnnualForecast";
import MedicationOverview from "./Visualizations/MedicationOverview/MedicationOverview";
import PrescriptionTrend from "./Visualizations/PrescriptionTrend/PrescriptionTrend";
import SearchVolMonthType from "./Visualizations/SearchVolMonthType/SearchVolMonthType";

// other components
import DirectCompetitorChart from "../CompetitiveAnalysis/Visualizations/DirectCompetitorChart";
import SearchVolBubble from "../Clusters/Visualizations/SearchVolBubble";

// hooks
import useProject from "../../hooks/useProject";
import useBrandAnalysis from "../../hooks/useBrandAnalysis";

import "./Project.css";


export function isLongerThanDate(date) {
  // threshhold date is sept 1 2024
  const threshhold = new Date("2024-09-01");
  const lastRun = new Date(date);

  return threshhold - lastRun > 30 * 24 * 60 * 60 * 1000;
}

const Project = () => {
  const location = useLocation();
  const history = location.state?.history || [];

  const uuid = useParams().projectId;
  const {
    project,
    loading: loadingProject,
    error: errorProject,
  } = useProject(uuid);
  const {
    brandData,
    loading: loadingBrand,
    error: errorBrand,
  } = useBrandAnalysis(uuid);

  if (loadingProject || loadingBrand) {
    return <Loading />;
  }

  if (errorProject || errorBrand) {
    return (
      <div className="project-page page-base">
        <ErrorDisplay error={errorProject || errorBrand} />
      </div>
    );
  }

  if (!project?.condition_analysis_name) {
    return (
      <div className="flex flex-col justify-center items-center">
        <p className="px-4 py-8 font-bold grid place-content-center">
          This project cannot be displayed because it does not have an
          associated condition.
        </p>
        <Link className="btn w-full md:max-w-[500px]" to="/companies">
          Back to Companies
        </Link>
      </div>
    );
  }

  if (isLongerThanDate(project.last_run)) {
    // only projects that have a condition analysis and have
    return (
      <div className="p-4 flex flex-col justify-center items-center space-y-8">
        <h1 className="text-2xl">
          This project must be refreshed prior to viewing
        </h1>
        <div className="flex flex-col gap-4 justify-center items-center">
          <div className="p-2 text-5xl rounded hover:border hover:border-[#0561B7]">
            <RefreshButton
              refresh_property="project"
              project={project}
              project_eligible_for_analysis={project.eligible_for_analysis}
            />
          </div>

          <Link to="/companies" className="btn w-full md:min-w-[500px]">
            Back to Companies
          </Link>
          <Link to="/project-status" className="btn w-full md:min-w-[500px]">
            View Project Status
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="project-page page-base">
      <section>
        {/* <DynamicCrumbs current={project.name} /> */}

        <ProjectInfoHeader className="mb-2" project={project}>
          {uuid === "e8d32602-0897-44a4-a14a-5152c1d44fdc" ? (
            <Link
              className="btn max-w-[206px]"
              to={`/project/${uuid}/acnu_dashboard`}
              state={{
                from: `/project/${uuid}`,
                history: [
                  ...history,
                  { name: project.name, path: `/project/${uuid}` },
                ],
              }}
            >
              ACNU Dashboard
              <FontAwesomeIcon className="mr-2" icon={faChartPie} />
            </Link>
          ) : (
            <button className="bg-gray-500 text-white rounded-sm px-4 py-3 font-bold flex justify-center items-center gap-4 cursor-not-allowed" disabled>
              ACNU Dashboard
              <FontAwesomeIcon className="mr-2" icon={faChartPie} />
            </button>
          )}
        </ProjectInfoHeader>

        <div className="page-section">
          <h2 className="section-header flex justify-between items-center">
            Overview of {project.name}
          </h2>

          <MedicationOverview
            project={project}
            brand={brandData}
            loading={loadingBrand || loadingProject}
          />
        </div>
      </section>

      <section className="page-section">
        <h2 className="section-header">
          Overview of {project.condition_analysis_name}
        </h2>

        <div className="overview">
          <img
            className="h-full w-full col-span-1 row-span-1 aspect-square border border-[#0561B7] border-opacity-50"
            src={project.condition_analysis?.condition_ai_image1 || image}
            alt=""
          />

          <Markdown className="md:col-span-5 space-y-2">
            {project?.condition_analysis?.frontpage_summary ||
              "Summary is not available."}
          </Markdown>

          <hr className="col-span-full border-[#0561B7] border-opacity-50 border-dotted" />

          <div className="col-span-full">
            <PrescriptionTrend
              data={project.condition_analysis.population_chart_input}
              project_name={project?.name}
            />
          </div>
        </div>
      </section>

      <section className="page-section">
        <h2 className="section-header">
          <Link
            className="flex justify-between items-center"
            to={`/project/${uuid}/keyword-universe`}
            state={{
              from: `/project/${uuid}`,
              history: [
                ...history,
                { name: project.name, path: `/project/${uuid}` },
              ],
            }}
          >
            Keyword Universe
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </h2>

        <SearchVolMonthType
          data={project?.keyword_universe}
          project_name={project?.name}
        />
      </section>

      <section className="page-section">
        <h2 className="section-header">
          <Link
            className="flex justify-between items-center"
            to={`/project/${uuid}/competitive-analysis`}
            state={{
              from: `/project/${uuid}`,
              history: [
                ...history,
                { name: project.name, path: `/project/${uuid}` },
              ],
            }}
          >
            Competitive Analysis
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </h2>

        <DirectCompetitorChart data={brandData.competitors} />
      </section>

      <section className="page-section">
        <h2 className="section-header">
          <Link
            className="flex justify-between items-center"
            to={`/project/${uuid}/acnu-feasibility`}
            state={{
              from: `/project/${uuid}`,
              history: [
                ...history,
                { name: project.name, path: `/project/${uuid}` },
              ],
            }}
          >
            ACNU Forecast
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </h2>

        <AnnualForecast data={project?.keyword_universe} />
      </section>

      <section className="page-section">
        <h2 className="section-header">
          <Link
            className="flex justify-between items-center"
            to={`/clusters/${uuid}`}
            state={{
              from: `/project/${uuid}`,
              history: [
                ...history,
                { name: project.name, path: `/project/${uuid}` },
              ],
            }}
          >
            Cluster Feed
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </h2>

        <SearchVolBubble
          data={project.search_volume_by_type}
          model_name={project.name}
          history={[
            ...history,
            { name: project.name, path: `/project/${uuid}` },
          ]}
          clusterId={uuid}
        />
      </section>
    </div>
  );
};

export default Project;
