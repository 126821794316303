import { useEffect, useState } from "react";

import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard.jsx";

import { formatMonths } from "../../../../utils/chartUtils.js";
import { useTheme } from "../../../../utils/ThemeManager.jsx";

// data: project.keyword_universe - Brand Subtotal, Condition Subtotal
// Search Volume by Month by Type
const SearchVolMonthType = ({ data, project_name }) => {
  const { theme } = useTheme();
  const [options, setOptions] = useState({});

  useEffect(() => {
    // if data does not include a proprty called 'Brand Subtotal' or 'Condition Subtotal' return a message
    if (data == null || data['Brand Subtotal'] == null || data['Condition Subtotal'] == null) return;

    // if Brand Subtotal or Condition Subtotal search_volume_monthly_totals is empty return a message
    if (
      data['Brand Subtotal'].search_volume_monthly_totals == null ||
      data['Brand Subtotal'].search_volume_monthly_totals?.length === 0 || 
      data['Condition Subtotal'].search_volume_monthly_totals == null ||
      data['Condition Subtotal'].search_volume_monthly_totals?.length === 0
    ) return;

    setOptions(chartOptions(data, project_name, theme));
  }, [theme]);

 // if data does not include a proprty called 'Brand Subtotal' or 'Condition Subtotal' return a message
  if(data == null || data['Brand Subtotal'] == null || data['Condition Subtotal'] == null) {
    return <div className="min-h-[450px] p-4 font-bold grid place-items-center">
      <p>Search Volume by Month by Type is not available.</p>
    </div>
  }

    // if Brand Subtotal or Condition Subtotal search_volume_monthly_totals is empty return a message
  if(
    data['Brand Subtotal'].search_volume_monthly_totals == null ||
    data['Brand Subtotal'].search_volume_monthly_totals?.length === 0 ||
    data['Condition Subtotal'].search_volume_monthly_totals == null ||
    data['Condition Subtotal']?.search_volume_monthly_totals?.length === 0
  ) {
    return <div className="min-h-[450px] p-4 font-bold grid place-items-center">
      <p>Search Volume by Month by Type is not available.</p>
    </div>
  }

  return <ChartDashboard config={options} async={true} id="consumer-journey" />;
};

export default SearchVolMonthType;

const chartOptions = (data, project_name, theme) => {
  const { 
    search_volume_monthly_totals: brandSubtotal, 
    search_volume_months: brandMonths 
  } = data['Brand Subtotal'];
  const { 
    search_volume_monthly_totals: conditionSubtotal, 
    search_volume_months: conditionMonths 
  } = data['Condition Subtotal'];


  const brandData = brandSubtotal.map((value, index) => [brandMonths[index], value]);
  const conditionData = conditionSubtotal.map((value, index) => [conditionMonths[index], value]);

  const colors = {
    dark: [
      {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1
        },
        stops: [
          [0, 'rgb(255, 20, 145, 1)'],
          [.999, 'rgb(255, 114, 71, 1)']
        ]
      },
      {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1
        },
        stops: [
          [0, '#13306F'],
        ]
      },
    ],        
    light: [
      {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1
        },
        stops: [
          [0, 'rgb(255, 20, 145, 1)'],
          [.999, 'rgb(255, 114, 71, 1)']
        ]
      },
      {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1
        },
        stops: [
          [0, '#0561B7'],
        ]
      },
    ],       
  }

  return {
      gui: {
        layouts: [
          {
            rows: [
              {
                cells: [
                  {
                    id: "consumer-journey-cell",
                  },
                ],
              },
            ],
          },
        ],
      },
      components: [
        {
          type: "Highcharts",
          cell: "consumer-journey-cell",
          chartOptions: {
            colors: colors[theme] || colors.dark,
            chart: {
              type: 'column',
              styledMode: false,
              height: 450,
            },
            accessibility: {
              description: 'Image description:'
            },
            title: {
              text: 'Search Volume by Month by Type',
            },
            subtitle: {
              text: `
              <div style="display: flex; row-gap: 1rem;">
                <div>${ project_name ? `Project: ${project_name}` : '' }</div>
                <div>|</div>
                <div>Source: <a style="color: #999" href="https://semrush.com" target="_blank">SEMRush</a></div>
              </div>
              `
            },
            xAxis: {
              type: 'datetime',
              tickmarkPlacement: 'on',
              accessibility: {
                rangeDescription: 'Range: 12 months.'
              },
              categories: formatMonths(data[Object.keys(data)[0]].search_volume_months),
            },
            yAxis: {
              title: {
                text: 'Total Search Volume',
              },
            },
            tooltip: { 
              useHTML: true,
              shadow: false,
              borderWidth: 2,
              headerFormat: '<table>',
              pointFormat:  `
              <tr><th>Month:</th><td>{point.category}</td></tr>
              <tr><th>Monthly Volume:</th><td>{point.y}</td></tr>
            `,
              footerFormat: '</table>'
            },
            plotOptions: {
              column: {
                pointPadding: 0.2,
                borderWidth: 0,
                pointPadding: 0,
                groupPadding: .145,
              },
            },
            series: [{
              name: 'Condition Search Volume',
              label: null,
              data: conditionData,
            },
            {
              name: 'Branded Search Volume',
              label: null,
              data: brandData,
            }]
        }
        },
      ],
  }
}