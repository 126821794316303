import { useParams } from "react-router-dom";

// global components
import ProjectInfoHeader from "../../components/ProjectInfoHeader/ProjectInfoHeader";
import ChartSlider from "../../components/ChartSlider/ChartSlider";
import ConsumerJourney from "../Project/Visualizations/SearchVolMonthType/SearchVolMonthType";
import Loading from "../../components/Loading/Loading";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";

// local components
import EstimatedSpendType from "./Visualizations/EstimatedSpend/Type";
import EstimatedSpendCategory from "./Visualizations/EstimatedSpend/Category";
import UniverseCharts from "./Visualizations/UniverseCharts/UniverseCharts";
import UniverseTable from "./Visualizations/UniverseTable/UniverseTable";
import Wordcloud from "./Visualizations/Wordcloud/Wordcloud";
import TopQuestions from "./Visualizations/TopQuestions/TopQuestions";


// hooks
import useProject from "../../hooks/useProject";

import "./KeywordUniverse.css";

const KeywordUniverse = () => {
  const uuid = useParams().projectId;
  const { project, loading, error } = useProject(uuid);

  if (loading) {
    return <Loading />;
  }

  if(error) {
    return <div className="keyword-universe-page page-base space-y-4 md:space-y-8">
      <ErrorDisplay error={error} />
    </div>
  }

  return (
    <div className="keyword-universe-page page-base space-y-4 md:space-y-8">

      <div>
        <ProjectInfoHeader className="mb-2" project={project} linkToProjectPage={true} />
        <section className="page-section">
          <h2 className="section-header">Overview</h2>
          <div className="p-4">
            <p>The Keyword Universe is a combination of all the keywords found in both the Condition and Branded Types.</p>
            <p>Each Type includes nested Categories like Diagnosis (Condition) or Side Effects (Branded).</p>
          </div>
        </section>
      </div>

      <section className="page-section">
        <h2 className="section-header">Consumer Journey</h2>

        <ChartSlider className="keyword-charts">
          <ConsumerJourney data={project?.keyword_universe} project_name={project?.name} />
          <UniverseCharts data={project?.keyword_universe} condition={project?.condition_analysis_name} category_type="Condition" />
          <UniverseCharts data={project?.keyword_universe} project_name={project?.name} category_type="Branded" />
          <Wordcloud data={project?.wordcloud_data} project_name={project?.name} />
        </ChartSlider>
      </section>

      <section className="page-section">
        <h2 className="section-header">Top Questions</h2>
        
        <TopQuestions data={project?.top_questions} loading={false} />
      </section>

      <section className="page-section">
        <h2 className="section-header">Inventory and Spend Estimates</h2>

        <ChartSlider>
          <EstimatedSpendType data={ project?.keyword_universe } project_name={project?.name} />
          <EstimatedSpendCategory data={ project?.keyword_universe } condition={project?.condition_analysis_name} category_type="Condition" />
          <EstimatedSpendCategory data={ project?.keyword_universe } project_name={project?.name} category_type="Branded" />
        </ChartSlider>

        <div className="p-4">
          <UniverseTable data={ project?.keyword_universe } />
        </div>
      </section>
    </div>
  );
};

export default KeywordUniverse;
