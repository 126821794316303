const SliderRow = ({ name, y, onValueChange }) => {
  return (
    <div className={`grid items-center mb-4`} style={{ gridTemplateColumns: '170px 1fr auto'}}>
      <div className={`mr-4`}>
        <strong className="block text-white">{name}</strong>
      </div>
      <input
        type="range"
        min={0}
        max={100}
        value={y}
        onChange={(e) => onValueChange(parseInt(e.target.value, 10))}
        aria-label={name}
        className={`flex-1 mr-4 accent-blue-500`}
      />
      <span className={`w-12 text-center`}>{y}%</span>
    </div>
  );
};

const DistributionSliders = ({ goals, setGoals }) => {
  const total = goals.reduce((sum, g) => sum + g.y, 0);

  const distributeEvenly = (goalsCopy, indexes, diff) => {
    const count = indexes.length;
    const share = Math.floor(diff / count);
    let remainder = diff % count;

    indexes.forEach((i) => {
      goalsCopy[i].y += share;
    });

    // Distribute remainder by adding 1 to some sliders
    for (let i = 0; i < indexes.length && remainder > 0; i++) {
      goalsCopy[indexes[i]].y += 1;
      remainder--;
    }
  };

  const distributeProportionally = (goalsCopy, indexes, diff) => {
    const sumUnlocked = indexes.reduce((acc, i) => acc + goalsCopy[i].y, 0);

    if (sumUnlocked === 0) {
      distributeEvenly(goalsCopy, indexes, diff);
      return;
    }

    let shares = [];
    let totalAssigned = 0;
    indexes.forEach((i) => {
      const val = goalsCopy[i].y;
      const share = Math.floor((val * diff) / sumUnlocked);
      shares.push({ i, share });
      totalAssigned += share;
    });

    let remainder = diff - totalAssigned;
    shares.forEach(({ i, share }) => {
      goalsCopy[i].y += share;
    });

    for (let k = 0; k < indexes.length && remainder > 0; k++) {
      goalsCopy[indexes[k]].y += 1;
      remainder--;
    }
  };

  // const handleValueChange = (index, newValue) => {
  //   setGoals((prevGoals) => {
  //     const goalsCopy = [...prevGoals];

  //     // If slider is locked, ignore this change
  //     if (goalsCopy[index].locked) {
  //       return prevGoals;
  //     }

  //     const oldValue = goalsCopy[index].y;
  //     const delta = newValue - oldValue;

  //     if (delta === 0) return prevGoals;

  //     goalsCopy[index].y = newValue;

  //     const unlockedIndexes = goalsCopy
  //       .map((g, i) => (!g.locked && i !== index ? i : null))
  //       .filter((i) => i !== null);

  //     let currentTotal = goalsCopy.reduce((sum, g) => sum + g.y, 0);
  //     let diff = 100 - currentTotal;

  //     if (diff === 0) {
  //       return goalsCopy; // Perfect after change
  //     }

  //     if (unlockedIndexes.length === 0 && diff !== 0) {
  //       // No place to redistribute
  //       goalsCopy[index].y = oldValue;
  //       return goalsCopy;
  //     }

  //     if (diff > 0) {
  //       // Need to add diff to unlocked indexes
  //       const sumUnlocked = unlockedIndexes.reduce((acc, i) => acc + goalsCopy[i].y, 0);
  //       if (sumUnlocked > 0) {
  //         distributeProportionally(goalsCopy, unlockedIndexes, diff);
  //       } else {
  //         distributeEvenly(goalsCopy, unlockedIndexes, diff);
  //       }
  //     } else {
  //       // diff < 0, remove value
  //       const absDiff = Math.abs(diff);
  //       const sumUnlocked = unlockedIndexes.reduce((acc, i) => acc + goalsCopy[i].y, 0);
  //       if (sumUnlocked > 0) {
  //         // Proportional removal
  //         let shares = [];
  //         let totalAssigned = 0;
  //         unlockedIndexes.forEach((i) => {
  //           const val = goalsCopy[i].y;
  //           const share = Math.floor((val * absDiff) / sumUnlocked);
  //           shares.push({ i, share });
  //           totalAssigned += share;
  //         });

  //         let remainder = absDiff - totalAssigned;

  //         shares.forEach(({ i, share }) => {
  //           goalsCopy[i].y -= share;
  //           if (goalsCopy[i].y < 0) goalsCopy[i].y = 0;
  //         });

  //         for (let k = 0; k < unlockedIndexes.length && remainder > 0; k++) {
  //           const idx = unlockedIndexes[k];
  //           if (goalsCopy[idx].y > 0) {
  //             goalsCopy[idx].y -= 1;
  //             remainder--;
  //           }
  //         }

  //         if (remainder > 0) {
  //           // Impossible
  //           goalsCopy[index].y = oldValue;
  //           return goalsCopy;
  //         }
  //       } else {
  //         // All unlocked are zero
  //         goalsCopy[index].y = oldValue;
  //         return goalsCopy;
  //       }
  //     }

  //     // Final check
  //     const finalSum = goalsCopy.reduce((sum, g) => sum + g.y, 0);
  //     const finalDiff = 100 - finalSum;
  //     if (finalDiff !== 0) {
  //       const finalUnlocked = goalsCopy
  //         .map((g, i) => (!g.locked ? i : null))
  //         .filter((i) => i !== null);

  //       if (finalUnlocked.length > 0) {
  //         distributeEvenly(goalsCopy, finalUnlocked, finalDiff);
  //       } else {
  //         goalsCopy[index].y = oldValue;
  //       }
  //     }

  //     return goalsCopy;
  //   });
  // };

  const getMaxRange = (goals, index) => {
    const totalOtherValues = goals.reduce((sum, goal, i) => (i !== index ? sum + goal.y : sum), 0);
    return Math.max(0, 100 - totalOtherValues); // Ensure at least 0
  };

  const handleValueChange = (index, newValue) => {
    setGoals((prevGoals) => {
      const maxRange = getMaxRange(prevGoals, index);

      // Clamp the slider's value to the allowed range
      const clampedValue = Math.min(newValue, maxRange);

      const goalsCopy = [...prevGoals];
      goalsCopy[index] = { ...goalsCopy[index], y: clampedValue };

      return goalsCopy;
    });
  };

  return (
    <div className="p-4 w-full mx-auto">
      <div className="flex justify-between items-center mb-4">
        <p className="text-sm">
          Adjust sliders to distribute the total percentage.
        </p>

        <span className={`min-w-[53px] text-center text-white rounded py-1 px-2 ${ total < 100 ? 'bg-watermelon-500' : 'bg-[#2DB300]'}`}>
          { total }%
        </span>
      </div>
      <div className='grid grid-cols-2 gap-4'>
      {goals.map((goal, i) => (
        <SliderRow
          key={i}
          name={goal.name}
          y={goal.y}
          onValueChange={(val) => handleValueChange(i, val)}

        />
      ))}
      </div>
    </div>
  );
};

export default DistributionSliders;
