import { useEffect, useState } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  RouterProvider,
  Route,
  useNavigate,
} from "react-router-dom";

// hooks
import useProjectRefreshStatus from "./hooks/useProjectRefreshStatus";

// components
import ACNUDashboard from "./pages/ACNUDashboard/ACNUDashboard";
import ACNUFeasibility from "./pages/ACNUFeasibility/ACNUFeasibility";
import Admin from "./pages/Admin/Admin";
import Cluster from "./pages/Cluster/Cluster";
import Clusters from "./pages/Clusters/Clusters";
import Companies from "./pages/Companies/Companies";
import Company from "./pages/Company/Company";
import CompanyAdd from "./pages/CompanyAdd/CompanyAdd";
import CompanyEdit from "./pages/CompanyEdit/CompanyEdit";
import CompetitiveAnalysis from "./pages/CompetitiveAnalysis/CompetitiveAnalysis";
import ConditionAnalysis from "./pages/ConditionAnalysis/ConditionAnalysis";
import ConditionEdit from "./pages/Admin/ConditionEdit/ConditionEdit";
import GenAI from "./pages/GenAI/GenAI";
import Header from "./components/Header/Header";
import KeywordCategories from "./pages/Admin/KeywordCategories/KeywordCategories";
import Loading from "./components/Loading/Loading";
import Missing from "./pages/Missing/Missing";
import KeywordUniverse from "./pages/KeywordUniverse/KeywordUniverse";
import ProductivityBooster from "./pages/ProductivityBooster/ProductivityBooster";
import Project from "./pages/Project/Project";
import ProjectEdit from "./pages/ProjectEdit/ProjectEdit";
import ProjectStatus from "./pages/ProjectStatus/ProjectStatus";
import ProjectKeywords from "./pages/ProjectKeywords/ProjectKeywords";
import ProtectedRoute from "./utils/ProtectedRoute";
import SignIn from "./pages/SignIn/SignIn";

// admin pages
import LibraryCatalog from "./pages/Admin/LibraryCatalog/LibraryCatalog";
import ConditionLibrary from "./pages/Admin/ConditionLibrary/ConditionLibrary";
import ConditionProfile from "./pages/Admin/ConditionProfile/ConditionProfile";
import KeywordUniverseAdmin from "./pages/Admin/KeywordUniverse/KeywordUniverse";
import KeywordProfile from "./pages/Admin/KeywordProfile/KeywordProfile";
import ForecastLeaderboard from "./pages/Admin/ForecastLeaderboard/ForecastLeaderboard";

// context
import { AppContext } from "./context/AppContext";

import session from "./stores/session";
import { $axios } from "./services/axiosInstance";

import "./App.css";
import "tailwindcss/tailwind.css";

const AuthenticatedContent = () => {
  const {
    updatingProjects,
    setUpdatingProjects,
    updatedProjects,
    projectStatusError,
    semrushAvailable,
    unitsAvailable,
  } = useProjectRefreshStatus();

  return (
    <AppContext.Provider
      value={{
        updatingProjects: updatingProjects,
        setUpdatingProjects: setUpdatingProjects,
        updatedProjects: updatedProjects,
        projectStatusError,
        semrushAvailable,
        unitsAvailable,
      }}
    >
      <Outlet /> {/* Renders the child routes */}
    </AppContext.Provider>
  );
};

const AdminContent = () => {
  const { user } = session.getState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPermissions = async () => {
      if (!user) {
        navigate("/companies", { replace: true });
        throw new Error("User not authenticated");
      }

      try {
        const { data } = await $axios.post("/permissions/");
        if (data.admin === true) {
          setIsAdmin(true);
        } else {
          navigate("/companies"); // Redirect to home or any other appropriate page
        }
      } catch (err) {
        console.error(err);
        navigate("/companies");
      } finally {
        setLoading(false);
      }
    };

    fetchPermissions();
  }, [navigate]);

  if (loading) {
    return <Loading />;
  }

  if (!isAdmin) {
    return null;
  }

  return <Outlet />;
};

const AppLayout = () => {
  return (
    <>
      <Header />
      <main className="container mx-auto">
        <Outlet />
      </main>
    </>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppLayout />}>
      {/* protected routes */}
      <Route element={<ProtectedRoute />}>
        <Route element={<AuthenticatedContent />}>
          <Route path="/genai" element={<GenAI />} exact />

          <Route element={<AdminContent />}>
            <Route path="/admin" element={<Admin />} exact />
            <Route path="/admin/library-catalog" element={<LibraryCatalog />} />
            <Route
              path="/admin/keyword-categories"
              element={<KeywordCategories />}
            />
            <Route
              path="/admin/condition-library"
              element={<ConditionLibrary />}
            />
            <Route
              path="/admin/condition-edit/:conditionId"
              element={<ConditionEdit />}
            />
            <Route
              path="/admin/condition-profile/:conditionId"
              element={<ConditionProfile />}
            />
            <Route
              path="/admin/keyword-universe"
              element={<KeywordUniverseAdmin />}
            />
            <Route
              path="/admin/keyword-profile/:keywordId"
              element={<KeywordProfile />}
            />
            <Route
              path="/admin/forecast-leaderboard"
              element={<ForecastLeaderboard />}
            />
          </Route>

          <Route path="/project-edit/:projectId" element={<ProjectEdit />} exact />

          <Route path="/project-status" element={<ProjectStatus />} exact />

          <Route path="/company-add" element={<CompanyAdd />} exact />
          <Route
            path="/company-edit/:companyId"
            element={<CompanyEdit />}
            exact
          />

          <Route path="/companies" element={<Companies />} exact />
          <Route path="/company/:companyId" element={<Company />} exact />

          <Route path="/project/:projectId/" element={<Project />} exact />
          <Route path="/project/:projectId/keyword-universe" element={<KeywordUniverse />} exact />
          <Route path="/project/:projectId/acnu-feasibility" element={<ACNUFeasibility />} exact />
          <Route path="/project/:projectId/acnu_dashboard" element={<ACNUDashboard />} exact />
          <Route path="/project/:projectId/competitive-analysis" element={<CompetitiveAnalysis />} exact />
          <Route path="/company/:companyId/project/:projectId/project-keywords" element={<ProjectKeywords />} exact />
          <Route path="/project/:projectId/condition-analysis" element={<ConditionAnalysis />} exact />
          <Route path="/clusters/:clusterId" element={<Clusters />} exact />
          <Route path="/cluster/:clusterId/:profileId" element={<Cluster />} exact />
        </Route>
      </Route>

      {/* public routes */}
      <Route
        path="/productivity-booster"
        element={<ProductivityBooster />}
        exact
      />
      <Route path="/" element={<SignIn />} exact />

      {/* catch all */}
      <Route path="*" element={<Missing />} />
    </Route>
  )
);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
