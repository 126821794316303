import { useState } from 'react';

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";

import './TopQuestions.css';

// data: project.top_questions
function TopQuestions({ data }) {
  const filterCategories1 = ['all', 'Condition', 'Lifestyle', 'Symptoms', 'Diagnosis', 'Treatment', 'Brand', 'Side Effects', 'OTC'];
  const filterCategories2 = ['when', 'where', 'can', 'is', 'who', 'how', 'should', 'which', 'what'];

  const [filter1, setFilter1] = useState(['all']);
  const [filter2, setFilter2] = useState([]);
  const [sortOrder, setSortOrder] = useState('desc');

  const toggleFilter1 = (value) => {
    setFilter1(prevState => {
      if (value === 'all') {
        return ['all'];
      } else {
        if (prevState.includes('all')) {
          return [value];
        }
        const newState = prevState.includes(value)
          ? prevState.filter(item => item !== value)
          : [...prevState, value];
        return newState.length === 0 ? ['all'] : newState;
      }
    });
  };

  const toggleFilter2 = (value) => {
    setFilter2(prevState => {
      const newState = prevState.includes(value)
        ? prevState.filter(item => item !== value)
        : [...prevState, value];
      return newState;
    });
  };

  const toggleSortOrder = () => {
    setSortOrder(prevState => prevState === 'desc' ? 'asc' : 'desc');
  };

  if (!data || Object.keys(data).length === 0) {
    return <p className="px-4 py-8 font-bold text-center">Top Questions data is not available.</p>
  }

  // Flattening and filtering the data structure
  const flattenAndFilterData = (nestedData) => {
    return Object.entries(nestedData).reduce((acc, [mainCategory, subCategories]) => {
      const matchesFilter1 = filter1.includes('all') || filter1.includes(mainCategory);

      if (matchesFilter1) {
        const subCategoryQuestions = Object.entries(subCategories).reduce((subAcc, [subCategory, questions]) => {
          const matchesFilter2 = filter2.length === 0 || filter2.includes(subCategory);

          if (matchesFilter2) {
            const questionArray = Object.entries(questions).map(([question, value]) => ({
              question,
              value: Number(value),  // Convert to number for sorting
              subCategory,
              mainCategory,
            }));
            return [...subAcc, ...questionArray];
          }

          return subAcc;
        }, []);

        return [...acc, ...subCategoryQuestions];
      }

      return acc;
    }, []);
  };

  const filteredData = flattenAndFilterData(data);

  // Sorting the filtered data
  const sortedData = filteredData.sort((a, b) => sortOrder === 'desc' ? b.value - a.value : a.value - b.value);

  return (
    <div className='top-questions'>
      <div className='border border-[#0561B7] border-opacity-50 rounded-sm overflow-hidden'>
        <div className="filter filter1-keys">
          {filterCategories1.map((category, index) => (
            <div key={`keys1-${index}`}>
              <input
                type="checkbox"
                name="filter1"
                id={`filter1-${category}`}
                value={category}
                checked={filter1.includes(category)}
                onChange={() => toggleFilter1(category)}
              />
              <label htmlFor={`filter1-${category}`} className={`label ${category}`}>
                {category.replace('-', ' ')}
              </label>
            </div>
          ))}
        </div>

        <hr className='my-1 mx-4 p-0 border border-[#0561B7]' />

        <div className="filter filter2-keys">
          {filterCategories2.map((category, index) => (
            <div key={`keys2-${index}`}>
              <input
                type="checkbox"
                name="filter2"
                id={`filter2-${category}`}
                value={category}
                checked={filter2.includes(category)}
                onChange={() => toggleFilter2(category)}
              />
              <label htmlFor={`filter2-${category}`} className={category}>
                {category}
              </label>
            </div>
          ))}
        </div>
      </div>

      <div className='flex-1 mt-4 border border-[#0561B7] border-opacity-50'>
        <div className='flex bg-[#F5F5F5] bg-opacity-100 dark:bg-[#0561B7] dark:bg-opacity-10'>
          <h3 className="flex-1 p-4 pl-4 font-bold text-lg md:text-xl">Popular Questions</h3>
          <div className="min-w-[70px] md:min-w-[120px] p-4 text-center font-bold text-lg md:text-xl cursor-pointer">
            <button className="w-full flex items-center justify-center gap-3" onClick={toggleSortOrder}>
              AMSV
              <FontAwesomeIcon icon={faSort} />
            </button>
          </div>
        </div>

        <div className='flex flex-col h-[500px] overflow-scroll'>
          { sortedData.map((question, index) => (
            <div key={`category-${index}`} className='flex'>
              <div className="flex-1 flex items-center gap-2 md:gap-4 p-4 border-b border-[#0561B7] border-opacity-50 last:border-none space-y-4 lg:text-xl">
                <div className='flex flex-col gap-1'>
                  <div className={`dot ${question.mainCategory}`} title={question.mainCategory}></div>
                  <div className={`dot ${question.subCategory}`} title={question.subCategory}></div>
                </div>
                {question.question}
              </div>
              <div className={`min-w-[94px] md:min-w-[120px] p-2 grid place-items-center text-center ${question.subCategory} text-lg md:text-2xl font-bold border-b border-[#0561B7] border-opacity-50`}>
                {Number(question.value).toLocaleString()}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TopQuestions;
