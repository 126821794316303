import { useState, useEffect } from "react";
import Highcharts from "highcharts";
import ChartDashboard from "../../../components/ChartDashboard/ChartDashboard.jsx";

import { useTheme } from "../../../utils/ThemeManager.jsx";
import { generateGradientColors } from "../../../utils/chartUtils.js";
// data: // data: cluster.visualization.paid_serp
// Search Volume by Month by Type
const PaidSERP = ({ data }) => {
  const { theme } = useTheme();
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (data == null || Object.keys(data).length === 0) return;

    setOptions(chartOptions(data, theme));
  }, [theme]);

  if (data == null || Object.keys(data).length === 0) {
    return (
      <p className="px-4 py-8 font-bold text-center">
        Paid SERP data is not available.
      </p>
    );
  }

  return (
    <ChartDashboard config={options} async={true} id="paid-serp" />
  );
};

export default PaidSERP;

const chartOptions = (data, theme) => {
  const colors = {
    dark: generateGradientColors("#FF1491", "#FF7247", data.length),
    light: generateGradientColors("#0561B7", "#C0D7ED", data.length),
  }

  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: "paid-serp-cell",
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: "paid-serp-cell",
        chartOptions: {
          colors: colors[theme],
          chart: {
            type: 'scatter',
            zoomType: 'xy',
            styledMode: false,
            height: 450,
          },
          title: {
            text: 'Paid SERP Competition',
          },
          subtitle: {
            text: 'Source: SEMRush',
          },
          xAxis: {
            reversed: true,
            title: {
              text: 'Cost Per Click',
            },
            labels: {
              formatter: function () {
                // Format the value as currency with two decimal places
                return '$' + this.value.toFixed(2);
              }
            },
          },
          yAxis: {
            title: {
              text: 'Competitive Density',
            },
            labels: {
              format: '{value}%',
            },
            max: 150,
          },
          legend: {
            enabled: false,
          },
          tooltip: {
            useHTML: true,
            formatter: function () {
              return `
                <div style="display: flex; flex-direction: column;">
                  <strong style="margin-bottom: 4px">${this.point.name}</strong>
                  <div style="display: grid; grid-template-columns: 1fr 1fr; grid-template-rows: 1fr 1fr">
                    <div>CPC:</div> <div>$${Highcharts.numberFormat(this.point.x, 2)}</div>
                    <div>Density:</div> <div>${Highcharts.numberFormat(this.point.y, 2)}%</div>
                  </div>
                </div>
              `;
            }
          },   
          plotOptions: {
            scatter: {
              marker: {
                radius: 5,
                symbol: 'circle',
                lineWidth: 1,
              },           
            },
          },
          series: data
        }
      },
    ],
  };
};
