// react
import { useEffect, useState } from "react";

// context
import { useAppContext } from "../../context/AppContext";

import ProjectDifficultyIcon from "../../components/ProjectList/ProjectDifficultyIcon";
import RefreshButton from "../../components/RefreshButton/RefreshButton";

import ExternalLink from "./ExternalLink/ExternalLink";

// styles
import "./ProjectStatus.css";
import RefreshStatus from "./RefreshStatus/RefreshStatus";



const formatDate = (dateString) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const ProjectStatus = () => {
  const { updatingProjects, updatedProjects, semrushAvailable, unitsAvailable } = useAppContext();
  const [queuedProjects, setQueuedProjects] = useState([]);
  const [inProgressProjects, setInProgressProjects] = useState([]);
  const [successProjects, setSuccessProjects] = useState([]);
  const [stuckProjects, setStuckProjects] = useState([]);
  const [failedProjects, setFailedProjects] = useState([]);
  const [semrushStatus, setSemrushStatus] = useState('unavailable');

  useEffect(() => {
    // Set the SEMrush status
    setSemrushStatus(semrushAvailable ? 'available' : 'unavailable');

    // Filter the updatingProjects array by status
    const queued = updatingProjects.filter(project => project.processing_status === 'Queued');
    setQueuedProjects(queued);
    
    const inProgress = updatingProjects.filter(project => project.processing_status === 'In Progress');
    setInProgressProjects(inProgress);
    
    const success = updatedProjects.filter(project => project.processing_status === 'Success');
    setSuccessProjects(success);

    const stuck = updatedProjects.filter(project => project.processing_status === 'Stuck');
    setStuckProjects(stuck);
    
    const failed = updatedProjects.filter(project => project.processing_status === 'Failure');
    setFailedProjects(failed);
  }, [updatingProjects, updatedProjects]); // Dependency on updatingProjects

  return <div className="project-status-page page-base">
    <div className="page-header">
      <div className="col-1">
      <h1 className="text-3xl md:text-4xl font-semibold mb-1">Project Status</h1>
      <p className="ml-3 text-[#838190]">View the status of all projects.</p>
      </div>

      <div className="col-2">
        <div className="semrush-status">
          <h2 className="sr-only">SEM Rush Status</h2>
          <div className="status-item">
            <h3>Available Units</h3>
            <p>{ unitsAvailable
              ? unitsAvailable.toLocaleString()
              : 0
            }</p>
          </div>
          <div className="status-item ">
            <h3>SEMRush Status</h3>
            <p className={semrushStatus}>{semrushStatus}</p>
          </div>
        </div>
      </div>
    </div>

    <section className="page-section queued">
      <div className="table-container overflow-x-scroll w-full">
        <table className="project-table status">
          <thead>
            <tr>
              <th className="align-middle">
                <h2>Queued</h2>
              </th>
              <th>Company</th>
              <th className="text-center">ACNU Difficulty</th>
              <th className="text-center">Run Initiated</th>
              <th className="text-center">Last Run</th>
            </tr>
          </thead>
          <tbody>
            {
              queuedProjects.length ? queuedProjects.map((project, index) => {
                return <tr key={`queued-${project.id}-${index}`}>
                  <td>
                    <ExternalLink url={`/project/${project.uuid}`} text={project.name} />
                  </td>
                  <td>
                    <ExternalLink url={`/company/${project.parent_company}`} text={project.parent_company_name} />
                  </td>
                  <td>
                    <ProjectDifficultyIcon acnu_difficulty={project.acnu_difficulty} />
                  </td>
                  <td>{formatDate(project.run_initiated)}</td>
                  <td>{formatDate(project.last_run)}</td>
                </tr>
              }) : <tr><td colSpan="5" className="no-data text-center">No projects are currently queued.</td></tr>
            }
          </tbody>
        </table>
      </div>
    </section>

    <section className="page-section in-progress">
      <div className="table-container overflow-x-scroll w-full">
        <table className="project-table status">
          <thead>
            <tr>
              <th className="align-middle">
                <h2>In Progress</h2>
              </th>
              <th>Company</th>
              <th className="text-center">ACNU Difficulty</th>
              <th className="text-center">Run Initiated</th>
              <th className="text-center">Last Run</th>
            </tr>
          </thead>
          <tbody>
            {
              inProgressProjects.length ? inProgressProjects.map(project => {
                return <tr key={`progress-${project.id}`}>
                  <td>
                    <RefreshStatus project_name={project.name} status={project.analyses_status} completion_percentage={project.completion_percentage} completion_time={project.completion_time} />
                    <ExternalLink url={`/project/${project.uuid}`} text={project.name} />
                  </td>
                  <td>
                    <ExternalLink url={`/company/${project.parent_company}`} text={project.parent_company_name} />
                  </td>
                  <td>
                    <ProjectDifficultyIcon acnu_difficulty={project.acnu_difficulty} />
                  </td>
                  <td className="text-sm justify-center">{formatDate(project.run_initiated)}</td>
                  <td className="text-sm justify-center">{formatDate(project.last_run)}</td>
                </tr>
              }) : <tr><td colSpan="5" className="no-data text-center">No projects are currently being processed.</td></tr>
            }
          </tbody>
        </table>
      </div>
    </section>

    <section className="page-section completed">
      <div className="table-container overflow-x-scroll w-full">
        <table className="project-table status complete">
          <thead>
            <tr>
              <th className="align-middle">
                <h2>Recently Completed</h2>
              </th>
              <th className="grid items-center">Company</th>
              <th className="align-middle">ACNU Difficulty</th>
              <th className="align-middle text-center">Completion Time (Minutes)</th>
              <th className="align-middle text-center">Last Run</th>
              <th className="align-middle text-center">Units Used</th>
              <th className="align-middle text-center">Cost</th>
            </tr>
          </thead>
          <tbody>
            {
              successProjects.length ? successProjects.map((project, index) => {
                return <tr key={`complete-${project.id}-${index}`}>
                  <td>
                    <RefreshStatus project_name={project.name} status={project.analyses_status} completion_percentage={project.completion_percentage} completion_time={project.completion_time} />
                    <RefreshButton refresh_property="project" project={project} />
                    <ExternalLink url={`/project/${project.uuid}`} text={project.name} />
                  </td>
                  <td>
                    <ExternalLink url={`/company/${project.parent_company}`} text={project.parent_company_name} />
                  </td>
                  <td>
                    <ProjectDifficultyIcon acnu_difficulty={project.acnu_difficulty} />
                  </td>
                  <td className="justify-center">
                    {project.completion_time !== 'N/A' ? Number(project.completion_time).toFixed(2) : 'N/A'}
                  </td>
                  <td className="text-sm justify-center">{formatDate(project.last_run)}</td>
                  <td className="justify-center">{project.semrush_units_used.toLocaleString()}</td>
                  <td className="justify-center">${project.latest_semrush_cost.toFixed(2)}</td>
                </tr>
              }) : <tr><td colSpan="5" className="no-data text-center">No projects have been processed.</td></tr>
            }
          </tbody>
        </table>
      </div>
    </section>

    <section className="page-section stuck">
      <div className="table-container overflow-x-scroll w-full">
        <table className="project-table status">
          <thead>
            <tr>
              <th className="align-middle">
                <h2>Stuck</h2>
              </th>
              <th>Company</th>
              <th className="text-center">ACNU Difficulty</th>
              <th className="text-center">Run Initiated</th>
              <th className="text-center">Last Run</th>
            </tr>
          </thead>
          <tbody>
            {
              stuckProjects.length ? stuckProjects.map(project => {
                return <tr key={`progress-${project.id}`}>
                  <td>
                    <RefreshButton project_name={project.name} refresh_property="project" project={project} />
                    <ExternalLink url={`/project/${project.uuid}`} text={project.name} />
                  </td>
                  <td>
                    <ExternalLink url={`/company/${project.parent_company}`} text={project.parent_company_name} />
                  </td>
                  <td>
                    <ProjectDifficultyIcon acnu_difficulty={project.acnu_difficulty} />
                  </td>
                  <td className="text-sm justify-center">{formatDate(project.run_initiated)}</td>
                  <td className="text-sm justify-center">{formatDate(project.last_run)}</td>
                </tr>
              }) : <tr><td colSpan="5" className="no-data text-center">No projects are currently stuck.</td></tr>
            }
          </tbody>
        </table>
      </div>
    </section>

    <section className="page-section failed">
      <div className="table-container overflow-x-scroll w-full">
        <table className="project-table status failure">
          <thead>
            <tr>
              <th>
                <h2>Failed</h2>
              </th>
              <th>Company</th>
              <th className="text-center">ACNU Difficulty</th>
              <th className="text-center">Completion Time (Minutes)</th>
              <th className="text-center">Last Run</th>
            </tr>
          </thead>
          <tbody>
            {
              failedProjects.length ? failedProjects.map((project, index) => {
                return <tr key={`failure-${project.id}-${index}`}>
                  <td>
                    <RefreshStatus project_name={project.name} status={project.analyses_status} completion_percentage={project.completion_percentage} completion_time={project.completion_time} />
                    <RefreshButton refresh_property="project" project={project} />
                    <ExternalLink url={`/project/${project.uuid}`} text={project.name} />
                  </td>
                  <td>
                    <ExternalLink url={`/company/${project.parent_company}`} text={project.parent_company_name} />
                  </td>
                  <td>
                    <ProjectDifficultyIcon acnu_difficulty={project.acnu_difficulty} />
                  </td>
                  <td className="justify-center">
                    {project.completion_time !== 'N/A' ? Number(project.completion_time).toFixed(2) : 'N/A'}
                  </td>
                  <td className="text-sm justify-center">{formatDate(project.last_run)}</td>
                </tr>
              }) : <tr><td colSpan="5" className="no-data text-center">No projects have failed to process.</td></tr>
            }
          </tbody>
        </table>
      </div>
    </section>
  </div>;
};

export default ProjectStatus;