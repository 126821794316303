import React, { useState } from "react";
import { Link } from "react-router-dom";
import ChartDashboard from "../../../components/ChartDashboard/ChartDashboard.jsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";

import { formatFloat, getSparklineOptions, formatPercent, formatCurrency } from "../../../utils/tableUtils.js";
import { useTheme } from "../../../utils/ThemeManager.jsx";

import "./ClusterTable.css";

// data: cluster.visualization.keywords
function ClusterTable({ data }) {
  const { theme } = useTheme();
  const [sortConfig, setSortConfig] = useState({ key: 'amsv', direction: 'descending' });

  // Sorting function
  const sortedKeywords = [...data].sort((a, b) => {
    let aValue = a[sortConfig.key] || "-";
    let bValue = b[sortConfig.key] || "-";
    
    // Skip sorting if either value is "-"
    if (aValue === "-" || bValue === "-") {
      return 1; // Keep the "-" entries at the bottom during sorting
    }
  
    if (sortConfig.key === 'value' || sortConfig.key === 'sentiment' || sortConfig.key === 'category') {
      // Normalize the values to lowercase and trim whitespace
      aValue = aValue.toLowerCase().trim();
      bValue = bValue.toLowerCase().trim();
  
      // Use localeCompare for more accurate alphabetical comparison
      return sortConfig.direction === 'ascending'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    } else {
      // Sort by numerical values for the rest of the columns
      aValue = a[sortConfig.key] || 0;
      bValue = b[sortConfig.key] || 0;
      return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
    }
  });

  // Function to handle sorting toggling
  const requestSort = (e) => {
    const key = e.target.getAttribute('value') || e.target.value;

    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortArrow = (columnKey) => {
    if (sortConfig.key === columnKey) {
      return sortConfig.direction === 'ascending' 
      ? <FontAwesomeIcon icon={faCaretUp} />
      : <FontAwesomeIcon icon={faCaretDown} />;
    }
    return null;
  };

  return (
    <table className="cluster-table">
      <thead className="bg-[#F5F5F5] bg-opacity-100 dark:bg-[#0561B7] dark:bg-opacity-10">
        <tr>
          <th className="cursor-pointer text-left whitespace-nowrap select-none" value="value" onClick={requestSort}>
            Keyword <span className="ml-2">{getSortArrow('value')}</span>
          </th>
          <th className="cursor-pointer text-center whitespace-nowrap select-none" value="category" onClick={requestSort}>
            Category <span className="ml-2">{getSortArrow('category')}</span>
          </th>
          <th className="cursor-pointer text-center whitespace-nowrap select-none" value="sentiment" onClick={requestSort}>
            Sentiment <span className="ml-2">{getSortArrow('sentiment')}</span>
          </th>
          <th className="cursor-pointer text-center whitespace-nowrap select-none" value="amsv" onClick={requestSort}>
            AMSV <span className="ml-2">{getSortArrow('amsv')}</span>
          </th>
          <th className="cursor-pointer text-center whitespace-nowrap select-none" value="difficulty" onClick={requestSort}>
            Difficulty <span className="ml-2">{getSortArrow('difficulty')}</span>
          </th>
          <th className="cursor-pointer text-center whitespace-nowrap select-none" value="cpc" onClick={requestSort}>
            Avg. CPC <span className="ml-2">{getSortArrow('cpc')}</span>
          </th>
          <th className="cursor-pointer text-center whitespace-nowrap select-none" value="opportunity" onClick={requestSort}>
            Opportunity <span className="ml-2">{getSortArrow('opportunity')}</span>
          </th>
          <th className="spark text-center whitespace-nowrap">
            Spark
          </th>

        </tr>
      </thead>
      <tbody>
            {sortedKeywords.map((keyword, index) => {
              const sparklineOptions = getSparklineOptions(`${index}`, keyword.spark, theme, theme === 'dark' ? '#FFFFFF' : '#0561B7');
              return (
              <tr className="even:bg-deep-space even:bg-opacity-5 even:dark:bg-white even:dark:bg-opacity-10" key={index}>
                <td className="text-left">
                  <Link to={`/admin/keyword-profile/${keyword.uuid}`}>
                    {keyword.value}
                  </Link>
                </td>
                <td className="text-center">{keyword.category || "-"}</td>
                <td className="text-center">{keyword.sentiment || "-"}</td>
                <td className="text-center">{formatFloat(keyword.amsv, 0)}</td>
                <td className="text-center">{formatPercent(keyword.difficulty, 0)}</td>
                <td className="text-center">{formatCurrency(keyword.cpc)}</td>
                <td className="text-center">{formatFloat(keyword.opportunity, 0)}</td>
                <td className="spark text-center">
                  <ChartDashboard config={sparklineOptions} async={true} id={'spark-' + index} />
                </td>
              </tr>
            )})}
          </tbody>
    </table>
  );
}

export default ClusterTable;
