import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

function DynamicCrumbs({ current }) {
  const location = useLocation();
  const navigate = useNavigate();
  const history = location.state?.history || [];

  if (!history || history.length === 0) {
    return null;
  }

  const handleDropdownChange = (e) => {
    const selectedIndex = e.target.value;
    if (selectedIndex !== "") {
      const selectedBreadcrumb = history[selectedIndex];
      navigate(selectedBreadcrumb.path, { state: { history: history.slice(0, selectedIndex) } });
    }
  };

  return (
    <nav aria-label="breadcrumbs">
      {/* Mobile View: Dropdown Select */}
      <div className="block md:hidden">
        <select
          className="w-full p-2 border border-gray-300 rounded-md text-sm"
          onChange={handleDropdownChange}
          defaultValue=""
        >
          <option value="" disabled>
            Navigate to...
          </option>
          {history.map((breadcrumb, index) => (
            <option key={index} value={index}>
              {breadcrumb.name}
            </option>
          ))}
          <option value="current" disabled>
            {current}
          </option>
        </select>
      </div>

      {/* Desktop View: Breadcrumbs */}
      <ol className="hidden md:flex gap-2 text-xs md:text-sm items-center">
        {history.map((breadcrumb, index) => (
          <li key={index} className="flex items-center">
            <Link to={breadcrumb.path} state={{ history: history.slice(0, index) }}>{breadcrumb.name}</Link>
            <FontAwesomeIcon
              className="ml-2 text-black dark:text-white"
              icon={faChevronRight}
              aria-hidden="true"
            />
          </li>
        ))}
        <li className="font-bold text-watermelon-500">
          {current}
        </li>
      </ol>
    </nav>
  );
}

export default DynamicCrumbs;
