/**
 * Checks if the given `data` object contains all the required nested properties.
 * 
 * The function accepts an array of strings representing required properties, which are in dot notation
 * (e.g., "Brand Subtotal.estimated_monthly_cost_trend"). It splits these strings and recursively checks
 * if each nested property exists in the provided `data` object.
 * 
 * @param {Object} data - The object to validate against the required properties.
 * @param {string[]} requiredProperties - An array of required properties in dot notation.
 * @returns {boolean} - Returns `true` if all the required properties are found in the `data` object, otherwise `false`.
 * 
 * @example
 * // Example data object
 * const data = {
 *   "Brand Subtotal": {
 *     "estimated_monthly_cost_trend": 123
 *   },
 *   "Condition Subtotal": {
 *     "estimated_monthly_cost_trend": 456
 *   },
 *   "Grand Total": {
 *     "search_volume_months": [1, 2, 3]
 *   }
 * };
 * 
 * // Example usage of the function
 * const requiredProperties = [
 *   "Brand Subtotal.estimated_monthly_cost_trend",
 *   "Condition Subtotal.estimated_monthly_cost_trend",
 *   "Grand Total.search_volume_months"
 * ];
 * 
 * const isValid = isValidData(data, requiredProperties);
 * console.log(isValid); // Outputs: true
 * 
 * // If a required property is missing
 * const incompleteData = {
 *   "Brand Subtotal": {
 *     "estimated_monthly_cost_trend": 123
 *   },
 *   "Condition Subtotal": {
 *     "estimated_monthly_cost_trend": 456
 *   }
 * };
 * 
 * const isValidIncomplete = isValidData(incompleteData, requiredProperties);
 * console.log(isValidIncomplete); // Outputs: false
 */
export const isValidData = (data, requiredProperties) => {
  return requiredProperties.every(prop => {
    const keys = prop.split('.');
    let value = data;
    for (const key of keys) {
      if (!value[key]) {
        return false;
      }
      value = value[key];
    }
    return true;
  });
};

// takes in an array of dates in the format "YYYY-MM"
// returns an array of formatted dates in the format "MMM YY"
function formatMonths(monthArray) {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  return monthArray.map(dateStr => {
    const [year, month] = dateStr.split("-");
    const monthIndex = parseInt(month, 10) - 1;
    const shortYear = year.slice(-2);
    return `${monthNames[monthIndex]} ${shortYear}`;
  });
}

function hexToRgb(hex) {
  // protect against cannot read slice of undefined
  if (!hex) {
    return;
  }

  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `${r}, ${g}, ${b}`;
}

function rgbToHex(r, g, b) {
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}

function interpolateColor(color1, color2, factor) {
  const hexToRgb = hex => {
    const bigint = parseInt(hex.slice(1), 16);
    return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
  };
  
  const rgbToHex = rgb => {
    return `#${rgb.map(x => x.toString(16).padStart(2, '0')).join('')}`;
  };

  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  const interpolated = rgb1.map((c1, i) => Math.round(c1 + factor * (rgb2[i] - c1)));
  return rgbToHex(interpolated);
}

function generateGradientColors(startColor, endColor, steps) {
  const colors = [];
  if(steps === 1) {
    return [startColor];
  }

  for (let i = 0; i < steps; i++) {
    colors.push(interpolateColor(startColor, endColor, i / (steps - 1)));
  }
  return colors;
}

const brandSort = {
  "Evaluation": 0,
  "Payment": 1,
  "Assistance": 2,
  "Usage": 3,
  "Side Effects": 4,
  "General Branded": 5,
}

const conditionSort = {
  "Preventative": 0,
  "Symptoms": 1,
  "Diagnosis": 2,
  "Treatment": 3,
  "Support": 4,
  "Clinical Trials": 5,
  "General Condition": 6,
}

export { brandSort, conditionSort, formatMonths, generateGradientColors, hexToRgb, rgbToHex };