import { useState, useEffect } from 'react';
import { $axios } from '../../../services/axiosInstance';

const useCluster = (clusterId, profileId) => {
  const [cluster, setCluster] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCluster = async () => {
      try {
        const { data } = await $axios.get(`/cluster-profile/${clusterId}/${profileId}/`);
        setCluster(data);
      } 
      catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCluster();
  }, [profileId]);

  return { cluster, loading, error };
};

export default useCluster;
