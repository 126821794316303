// react
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Loading from "../../components/Loading/Loading";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";

// local components
import DistributionSliders from "./DistributionSliders/DistributionSliders";
import KeywordField from "../../components/KeywordField/KeywordField";
import InputField from "./InputField";

// axios
import { $axios } from "../../services/axiosInstance";

import useProject from "../../hooks/useProject";

import "./ProjectEdit.css";

const ProjectEdit = () => {
  const uuid = useParams().projectId;
  const navigate = useNavigate();

  const { project, loading, error } = useProject(uuid);
  const [formError, setFormError] = useState({
    forecast: false,
    goals: false,
  });
  const [formLoading, setFormLoading] = useState(false);
  const [formData, setFormData] = useState({
    parent_company: "",
    name: "",
    url: "",
    brand_goals: "",
    alt_keywords: [],
    root_keywords: [],
    negative_words: [],
    // semrush_keyword_count: 50,
    start_percentage: 0,
    outcome_percentage: 0,
    ok_to_use_percentage: 0,
    purchase_percentage: 0,
    monthly_price: 0,
    monthly_cost: 0,
  });

  const [goals, setGoals] = useState([
    { name: "Increase Brand Awareness", y: 10 },
    { name: "Educate Target Audiences", y: 20 },
    { name: "Drive Engagement", y: 10 },
    { name: "Generate Leads", y: 6 },
    { name: "Boost Website Traffic", y: 22 },
    { name: "Support Conventions", y: 18 },
    { name: "Optimize User Experience", y: 4 },
    { name: "Improve Products and Services", y: 10 },
  ]);

  const [forecast, setForecast] = useState([
    { name: "Start", y: 30 },
    { name: "Outcome", y: 6 },
    { name: "Ok to Use", y: 10 },
    { name: "Purchase", y: 22 },
    { name: "Monthly Price", y: 28 },
    { name: "Monthly Cost", y: 4 },
  ]);

  useEffect(() => {
    if (project) {
      setFormData({
        parent_company_name: project.parent_company_name || "",
        name: project.name || "",
        url: project.url || "",
        brand_goals: project.brand_goals || "",
        alt_keywords: project.alt_keywords || [],
        root_keywords: project.root_keywords || [],
        negative_words: project.negative_words || [],
        // semrush_keyword_count: project.semrush_keyword_count,
        start_percentage: project.start_percentage || 0,
        outcome_percentage: project.outcome_percentage || 0,
        ok_to_use_percentage: project.ok_to_use_percentage || 0,
        purchase_percentage: project.purchase_percentage || 0,
        monthly_price: project.monthly_price || 0,
        monthly_cost: project.monthly_cost || 0,
      });

      if (project.goals) {
        setGoals(project.goals);
      }

      if (project.forecast) {
        setForecast(project.forecast);
      }
    }
  }, [project]);

  const validateForm = () => {
    let isValid = true; // Track overall validation state
  
    // Validate ACNU Forecast adds up to 100%
    const forecastTotal = forecast.reduce((sum, g) => sum + g.y, 0);
    if (forecastTotal !== 100) {
      setFormError((prev) => ({ ...prev, forecast: true }));
      isValid = false; // Mark validation as failed
    } else {
      setFormError((prev) => ({ ...prev, forecast: false })); // Clear forecast error
    }
  
    // Validate goals add up to 100%
    const goalsTotal = goals.reduce((sum, g) => sum + g.y, 0);
    if (goalsTotal !== 100) {
      setFormError((prev) => ({ ...prev, goals: true }));
      isValid = false; // Mark validation as failed
    } else {
      setFormError((prev) => ({ ...prev, goals: false })); // Clear goals error
    }
  
    return isValid; // Return overall validation result
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAppendItem = (list, item) => {
    if (!item.trim()) return; // Prevent empty strings
    setFormData((prev) => {
      const currentList = prev[list] || [];
      const normalizedItem = item.trim().toLowerCase(); // Normalize case
      const isDuplicate = currentList.some(
        (existingItem) => existingItem.toLowerCase() === normalizedItem
      ); // Case-insensitive check
      if (isDuplicate) return prev; // Avoid duplicates
      return {
        ...prev,
        [list]: [...currentList, item.trim()],
      };
    });
  };

  const handleRemoveItem = (list, index) => {
    setFormData((prev) => ({
      ...prev,
      [list]: prev[list].filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormLoading(true);
    setFormError({ forecast: false, goals: false });

    if (!validateForm()) {
      setFormLoading(false);
      return;
    }

    const updatedProject = {
      ...project,
      url: formData.url,
      condition: formData.condition,
      brand_goals: formData.brand_goals,
      negative_words: formData.negative_words,
      start_percentage: formData.start_percentage,
      outcome_percentage: formData.outcome_percentage,
      ok_to_use_percentage: formData.ok_to_use_percentage,
      purchase_percentage: formData.purchase_percentage,
      monthly_price: formData.monthly_price,
      monthly_cost: formData.monthly_cost,
      alt_keywords: formData.alt_keywords,
      root_keywords: formData.root_keywords,
      goals: goals,
      forecast: forecast,
    };

    try {
      const { data } = await $axios.put(
        `/company/project/${project.uuid}/`,
        updatedProject
      );
      if (data.last_run === null) {
        navigate(`/companies`);
      } else {
        navigate(`/project/${project.uuid}`);
      }
    } catch (error) {
      setFormError(error.message);
      console.error(error);
    } finally {
      setFormLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  return (
    <>
      <div
        className={`project-add-edit page-base ${
          formLoading ? "animate-pulse" : ""
        }`}
      >
        <h1 className="text-2xl text-deep-space dark:text-[#C0D7ED] font-bold">
          Edit Project
        </h1>
        <form className="form space-y-8" onSubmit={handleSubmit}>
          <div className="page-section">
            <h2 className="section-header">Project Info</h2>
            <div className="form-group-container">
              {/* Company Name */}
              <InputField
                id="companyName"
                name="company_name"
                label="Company Name"
                value={formData.parent_company_name}
                onChange={handleInputChange}
                disabled={true}
              />
              {/* Project Name */}
              <InputField
                id="projectName"
                name="name"
                label="Project Name"
                value={formData.name}
                onChange={handleInputChange}
                disabled={true}
              />
              {/* Project URL */}
              <InputField
                id="projectUrl"
                className="md:col-span-full"
                name="url"
                label="Project URL"
                value={formData.url}
                onChange={handleInputChange}
              />
              {/* Condition Dropdown */}
              <div className="form-group">
                <label htmlFor="condition">Condition</label>
                <select
                  id="condition"
                  name="condition"
                  value={formData.condition}
                  onChange={handleInputChange}
                >
                  <option value="">Conditions</option>
                  <option value="Autoimmune and Rheumatologic Diseases">
                    Autoimmune and Rheumatologic Diseases
                  </option>
                  <option value="Cardiovascular Disorders">
                    Cardiovascular Disorders
                  </option>
                  <option value="Dermatological Disorders">
                    Dermatological Disorders
                  </option>
                  <option value="Oncological Conditions">
                    Oncological Conditions
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div
            className={`page-section ${
              formError.goals
                ? "border-watermelon-500 dark:border-watermelon-500"
                : ""
            }`}
          >
            <h2 className="section-header">Audience and Goals</h2>

            <DistributionSliders goals={goals} setGoals={setGoals} />

            <label
              className="form-group p-4 pt-0 flex gap-2"
              htmlFor="goals-description"
            >
              <span className="text-sm">Brand Goals Description</span>
              <textarea
                className="resize-none flex-1"
                id="goals-description"
                name="goals-description"
                value={formData.brand_goals}
                onChange={handleInputChange}
                rows="5"
              />
            </label>
          </div>

          <div className="page-section">
            <h2 className="section-header">Keywords</h2>

            <div className="p-4 flex flex-col gap-4">
              <KeywordField
                label="Alternate Names"
                placeholder="Add Alternate Names"
                list={formData.alt_keywords || []}
                type="alt_keywords"
                onAdd={handleAppendItem}
              >
                {formData.alt_keywords?.map((keyword, index) => (
                  <KeywordField.KeywordItem
                    onRemove={() => handleRemoveItem("alt_keywords", index)}
                    key={`alt_keywords-${index}`}
                  >
                    {keyword}
                  </KeywordField.KeywordItem>
                ))}
              </KeywordField>

              <KeywordField
                label="Root Keywords"
                placeholder="Add Roote Keywords"
                list={formData.root_keywords || []}
                type="root_keywords"
                onAdd={handleAppendItem}
              >
                {formData.root_keywords?.map((keyword, index) => (
                  <KeywordField.KeywordItem
                    onRemove={() => handleRemoveItem("root_keywords", index)}
                    key={`root_keywords-${index}`}
                  >
                    {keyword}
                  </KeywordField.KeywordItem>
                ))}
              </KeywordField>

              <KeywordField
                label="Top Negative Keywords"
                placeholder={"Add Top Negative Keyword"}
                list={formData.negative_words || []}
                type="negative_words"
                onAdd={handleAppendItem}
              >
                {formData.negative_words?.map((keyword, index) => (
                  <KeywordField.KeywordItem
                    onRemove={() => handleRemoveItem("negative_words", index)}
                    key={`negative_words-${index}`}
                  >
                    {keyword}
                  </KeywordField.KeywordItem>
                ))}
              </KeywordField>
            </div>
          </div>

          <div
            className={`page-section ${
              formError.forecast
                ? "border-watermelon-500 dark:border-watermelon-500"
                : ""
            }`}
          >
            <h2 className="section-header">ACNU Forecast</h2>

            <DistributionSliders goals={forecast} setGoals={setForecast} />
          </div>

          <div>
            { formError.forecast && <p className="text-center italic text-watermelon-500 dark:text-watermelon-500">ACNU Forecast must add up to 100%</p> }
            { formError.goals && <p className="text-center italic text-watermelon-500 dark:text-watermelon-500">Audience and Goals must add up to 100%</p> }

            <div className="p-4 grid place-items-center">
              <button type="submit" className="btn" disabled={formLoading}>
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ProjectEdit;
