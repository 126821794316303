import { useState, useEffect, useRef } from "react";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faListCheck,
  faArrowsRotate,
  faCircleQuestion,
  faCircleCheck,
  faCircleXmark,
  faClose,
  faClock,
  faPercentage,
} from "@fortawesome/free-solid-svg-icons";

//example data

function RefreshStatus({ project_name, status, completion_time, completion_percentage }) {
  const lightboxRef = useRef(null);
  const openButtonRef = useRef(null);
  const closeButtonRef = useRef(null);

  const [isVisible, setIsVisible] = useState(false);
  const [refreshStatus, setRefreshStatus] = useState(status);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (isVisible) {
      const focusableElements = lightboxRef.current.querySelectorAll(
        "button, [href], input, select, textarea, [tabindex]:not([tabindex='-1'])"
      );

      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      const handleTabKey = (e) => {
        if (e.key === "Tab") {
          if (e.shiftKey) {
            if (document.activeElement === firstElement) {
              e.preventDefault();
              lastElement.focus();
            }
          } else {
            if (document.activeElement === lastElement) {
              e.preventDefault();
              firstElement.focus();
            }
          }
        }
      };

      const handleKeyDown = (e) => {
        if (e.key === "Escape") {
          setIsVisible(false);
        }
      };

      document.addEventListener("keydown", handleTabKey);
      document.addEventListener("keydown", handleKeyDown);
      firstElement.focus();

      return () => {
        document.removeEventListener("keydown", handleTabKey);
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [isVisible]);

  useEffect(() => {
    if (!isVisible && openButtonRef.current && hasInteracted) {
      openButtonRef.current.focus();
    }
  }, [isVisible, hasInteracted]);

  useEffect(() => {
    setRefreshStatus(status);
  }, [status]);

  const handleOpen = () => {
    setIsVisible(true);
    setHasInteracted(true);
  };

  const handleShowMessage = () => {
    setShowMessage(!showMessage);
  };


  return (
    <>
      <button onClick={handleOpen} ref={openButtonRef} disabled={status == null || Object.keys(status).length === 0}>
        <FontAwesomeIcon icon={faListCheck} title="Refresh Status" />
      </button>

      {isVisible && (
        <div
          ref={lightboxRef}
          className="fixed inset-0 bg-black bg-opacity-70 z-[51] p-4 content-start md:content-center"
          onClick={() => setIsVisible(false)}
          data-testid="lightbox-backdrop"
        >
          <div
            className="bg-white dark:bg-deep-space page-section w-full md:w-3/4 lg:w-1/2 mx-auto rounded-lg"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="section-header flex justify-between">
              <h2>Refresh Status: {project_name}</h2>
              <button onClick={() => setIsVisible(false)} ref={closeButtonRef}>
                <FontAwesomeIcon icon={faClose} title="Close" />
              </button>
            </div>
            <div className="grid grid-cols-2 gap-4 p-4">
              {refreshStatus &&
                Object.entries(refreshStatus).map(([key, value], index) => {
                  let icon, color;
                  switch (value.status) {
                    case "Success":
                      icon = faCircleCheck;
                      color = "text-[#2DB300]";
                      break;
                    case "Failure":
                      icon = faCircleXmark;
                      color = "text-[#B2294B]";
                      break;
                    case "":
                      icon = faArrowsRotate;
                      color = "text-[#FFD700]";
                      break;
                    default:
                      icon = faCircleQuestion;
                      color = "text-[#FF7347]";
                      break;
                  }

                  return (
                    <div
                      key={index}
                      className="grid items-baseline"
                      style={{ gridTemplateRows: "1fr auto", gridTemplateColumns: "auto 1fr" }}
                    >
                        <FontAwesomeIcon
                            className={`mr-2 ${color}`}
                            icon={icon}
                            title={value.status}
                            spin={value.status === ""}
                          />
                        <h3 className="space-x-2" onClick={handleShowMessage}>
                          {key}
                        </h3>
                        { value?.message && <p className="mt-1 text-xs col-start-2 row-start-2">
                          {value.message}
                        </p> }
                      </div>

                  );
                })}
            </div>
            <div className="section-header text-sm flex justify-end gap-6">
              <p><FontAwesomeIcon className="mr-2" icon={faClock} title="completion time" /> {completion_time ? completion_time.toFixed(2) : 0.00}</p>
              <p><FontAwesomeIcon className="mr-2" icon={faPercentage} title="completion percentage" /> {completion_percentage}%</p>
              </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RefreshStatus;
