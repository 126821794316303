import React, { useEffect, useRef, useState } from 'react';
import { useSprings, animated } from '@react-spring/web';
import useMeasure from 'react-use-measure';
import clamp from 'lodash.clamp';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";

import './ChartSlider.css';

function ChartSlider({ children, className }) {
  const childrenArray = React.Children.toArray(children);
  const index = useRef(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [keyMap, setKeyMap] = useState({
    ArrowRight: false,
    ArrowLeft: false
  });

  const [ref, { width }] = useMeasure();
  const [props, api] = useSprings(
    childrenArray.length,
    i => ({
      x: i * width,
      display: 'block',
    }),
    [width]
  );

  useEffect(() => {
    setActiveIndex(index.current);
  }, [index.current]);

  // Event listener for key left and key right
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowRight') {
        index.current = clamp(index.current + 1, 0, childrenArray.length - 1);
        api.start(i => {
          if (i < index.current - 1 || i > index.current + 1) return { display: 'none' };
          const x = (i - index.current) * width;
          return { x, display: 'block' };
        });
        setActiveIndex(index.current); // Update the active index
        setKeyMap({ ArrowRight: true, ArrowLeft: false });
      }

      if (e.key === 'ArrowLeft') {
        index.current = clamp(index.current - 1, 0, childrenArray.length - 1);
        api.start(i => {
          if (i < index.current - 1 || i > index.current + 1) return { display: 'none' };
          const x = (i - index.current) * width;
          return { x, display: 'block' };
        });
        setActiveIndex(index.current); // Update the active index
        setKeyMap({ ArrowRight: false, ArrowLeft: true });
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
        setKeyMap({ ArrowRight: false, ArrowLeft: false });
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [width, api]);

  return (
    <div className={`slider-container ${ className || '' }`}>
      <div ref={ref} className="swipe-wrapper">
        {props.map(({ x, display }, i) => (
          <animated.div className="swipe-page" key={i} style={{ display, x }}>
            <animated.div className="swipe-page-content">
              {childrenArray[i]}
            </animated.div>
          </animated.div>
        ))}
      </div>

      <div className='flex justify-between gap-4 py-2 px-4 bg-[#f5f5f5] bg-opacity-100 dark:bg-[#0561B7] dark:bg-opacity-10'>
        <div className="navigation-dots flex items-center justify-center gap-2">
          {childrenArray.map((_, i) => (
            <button
              key={i}
              className={`dot ${activeIndex === i ? 'active' : ''}`}
              onClick={() => {
                index.current = i;
                api.start(i => {
                  if (i < index.current - 1 || i > index.current + 1) return { display: 'none' };
                  const x = (i - index.current) * width;
                  return { x, display: 'block' };
                });
                setActiveIndex(i); // Update the active index
              }}
            />
          ))}
        </div>

        <div className="navigation-arrows flex items-center justify-center gap-6 text-2xl">
          <button
            aria-label="Previous slide"
            className={`arrow left nav-button ${keyMap.ArrowLeft ? 'active' : ''}`}
            onClick={() => {
              index.current = clamp(index.current - 1, 0, childrenArray.length - 1);
              api.start(i => {
                if (i < index.current - 1 || i > index.current + 1) return { display: 'none' };
                const x = (i - index.current) * width;
                return { x, display: 'block' };
              });
              setActiveIndex(index.current); // Update the active index
            }}
          >
            <FontAwesomeIcon icon={faCaretLeft} />
          </button>

          <button
            aria-label="Next slide"
            className={`arrow right nav-button ${keyMap.ArrowRight ? 'active' : ''}`}
            onClick={() => {
              index.current = clamp(index.current + 1, 0, childrenArray.length - 1);
              api.start(i => {
                if (i < index.current - 1 || i > index.current + 1) return { display: 'none' };
                const x = (i - index.current) * width;
                return { x, display: 'block' };
              });
              setActiveIndex(index.current); // Update the active index
            }}
          >
            <FontAwesomeIcon icon={faCaretRight} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChartSlider;
