import { useState } from "react";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";

// axios
import { $axios } from "../../services/axiosInstance";

// context
import { useAppContext } from "../../context/AppContext";

const RefreshButton = ({ refresh_property, project }) => {
  const { updatingProjects, setUpdatingProjects } = useAppContext();

  const [loading, setLoading] = useState(false);
  const refreshMap = {
    'project': {
      endpoint: 'complete_project_refresh_with_semrush',
      button_display: 'Refresh Project Data',
    },
    'competitiveLandscape': {
      endpoint: 'refresh_competitive_landscape',
      button_display: 'Refresh Competitive Landscape data',
    },
    'competitors': {
      endpoint: 'refresh_competitors',
      button_display: 'Refresh Direct Competitor data',
    },
    'demographicAnalysis': {
      endpoint: 'refresh_demographic_analysis',
      button_display: 'Refresh Demographic Analysis data',
    },
    'keywordList': {
      endpoint: 'refresh_keyword_list',
      button_display: 'Refresh Keyword List data',
    },
    'searchChart': {
      endpoint: 'refresh_search_chart_data',
      button_display: 'Refresh Consumer Search data',
    },
    'swotAnalysis': {
      endpoint: 'refresh_swot_analysis',
      button_display: 'Refresh S.W.O.T. Analysis data',
    },
  }

  const handleRefresh = async () => {
    setLoading(true);

    try {
      await $axios.put(`/company/project/${project.uuid}/${refreshMap[refresh_property].endpoint}/`)
      
      setUpdatingProjects([...updatingProjects, {...project, status: 'Queued'}]);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }

  const shouldSpinIcon = () => {
    return loading || project.processing_status === 'Queued' || project.processing_status === 'In Progress';
  };

  const isButtonDisabled = () => {
    return (
      !project ||
      loading ||
      project.processing_status === 'Queued' ||
      project.processing_status === 'In Progress' ||
      project.eligible_for_analysis !== true ||
      project.condition_analysis_name === null
    );
  };
  
  return (
    <button
      className={`refresh-btn hover:text-[#0561B7] dark:hover:text-watermelon-500 aspect-square disabled:opacity-50`}
      title={refreshMap[refresh_property].button_display}
      onClick={handleRefresh}
      disabled={isButtonDisabled()}
      data-testid="refresh-button"
    >
      <FontAwesomeIcon icon={faArrowsRotate} spin={shouldSpinIcon()} />
      <span className="sr-only">
        { refreshMap[refresh_property].button_display }
      </span>
    </button>
  )
}

export default RefreshButton;