import { useEffect, useState } from "react";
import ChartDashboard from "../../../components/ChartDashboard/ChartDashboard.jsx";
import { useTheme } from "../../../utils/ThemeManager.jsx";
import { generateGradientColors } from "../../../utils/chartUtils.js";

import "./DirectCompetitorChart.css";

// formatting the large dollar amounts returned in this chart data
export const largeNumberFormatter = (num) => {
  if (num > 999999999) {
    return `${(num / 1000000000).toFixed(1)}B`;
  } else if (num > 999999) {
    return `${(num / 1000000).toFixed(1)}M`;
  } else if (num > 999) {
    return `${(num / 1000).toFixed(1)}K`;
  } else {
    return num;
  }
};

const DirectCompetitorChart = ({ data, project_name }) => {
  const { theme } = useTheme();
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (!data || Object.keys(data).length === 0) return;

    setOptions(chartOptions(data, project_name, theme));
  }, [theme]);

  if (!data || Object.keys(data).length === 0) {
    return (
      <div className="direct-comp-chart">
        <p>No direct competitor data available</p>
      </div>
    );
  }

  return (
    <div className="direct-comp-chart">
      <ChartDashboard config={options} id="direct-competitor" />
    </div>
  );
};

export default DirectCompetitorChart;

const chartOptions = (data, project_name, theme) => {
  const colors = {
    dark: generateGradientColors("#FFA063", "#FF3C6D", data.length),
    light: generateGradientColors("#0561B7", "#020021", data.length),
  };

  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `direct-competitor-cell`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `direct-competitor-cell`,
        chartOptions: {
          colors: colors[theme],
          chart: {
            type: "bubble",
            zoomType: "xy",
            reflow: true,
            styledMode: false,
            height: 450,
          },
          legend: {
            enabled: false,
          },
          title: {
            text: null,
          },
          subtitle: {
            text: project_name ? `Project: ${project_name}` : '',
            verticalAlign: "bottom",
            align: "right",
            
          },
          accessibility: {
            point: {
              valueDescriptionFormat:
                "{index}. {point.brand_name}, launch year: {point.x}, since-launch revenue: ${point.y}",
            },
          },
          xAxis: {
            title: {
              text: "Launch Year",
            },
            labels: {
              format: "{value}",
            },
            accessibility: {
              rangeDescription: "Range: 1990 to 2006.",
            },
          },

          yAxis: {
            startOnTick: false,
            endOnTick: false,
            title: {
              text: "Since-launch Revenue (USD)",
            },
            labels: {
              formatter: function () {
                return largeNumberFormatter(+this.value);
              },
            },
            maxPadding: 0.2,
            plotLines: [
              {
                dashStyle: "dot",
                width: 1,
                value: 0,
                label: {
                  text: "",
                  x: -10,
                },
                zIndex: 3,
              },
            ],
            accessibility: {
              rangeDescription: "Range: $1 Billion USD to $150 Billion USD",
            },
          },
          tooltip: {
            formatter: function () {
              return `<h3 style="font-weight: bold; font-size: 1rem">${
                this.point.brand_name
              }</h3>
            <br />
            <p>
              <strong>Launch Year:</strong> ${this.point.x}
            </p>
            <br />
            <p>
              <strong>Since-Launch Revenue:</strong> $${largeNumberFormatter(
                this.point.y
              )}
            </p>
            <br />
            <p>
              <strong>Manufacturer:</strong> ${this.point.manufacturer}
            </p>
            <br />
            <p>
              <strong>Generic Name:</strong> ${this.point.z}
            </p>`;
            },
          },
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
                format: "{point.brand_name}",
              },
            },
          },
          series: [
            {
              data: data,
              colorByPoint: true,
            },
          ],
        },
      },
    ],
  };
};
