// react
import { Link } from "react-router-dom";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faLink, faCalendar } from "@fortawesome/free-solid-svg-icons";

import RefreshButton from "../RefreshButton/RefreshButton";

const ProjectInfoHeader = ({ project, linkToProjectPage, className, children }) => {
  if (!project) return null;

  return (
    <div className={ className }>
      <div className="flex flex-col md:flex-row md:gap-4 md:items-center col-span-1">
        <div className="grid flex-1">
          <div className="flex gap-4 items-center">
            { linkToProjectPage 
              ? <Link to={`/project/${project.uuid}`}> <h1 className="text-2xl font-sans font-semibold hover:text-watermelon-500">{project.name}</h1> </Link>
              : <h1 className="text-2xl font-sans font-semibold">{project.name}</h1>
            }
            
            <Link
              to={`/project-edit/${project.uuid}`}
              state={{
                from: `/project/${project.uuid}`,
                project: project,
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
              <span className="sr-only">Edit {project.name}</span>
            </Link>

            <RefreshButton
              refresh_property="project"
              project={project}
              project_eligible_for_analysis={project.eligible_for_analysis}
            />

            {project.url && (
              <a href={project.url} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faLink} />
              </a>
            )}
          </div>

          {project.last_run && (
            <div className="text-sm mt-1 mb-2 ml-4">
              {/* show date and time */}
              <Link to="/project-status" title="last run">
                <FontAwesomeIcon className="mr-2" icon={faCalendar} />
                {new Date(project.last_run).toLocaleString()}
              </Link>
            </div>
          )}
        </div>

        { children && <div className="children-section">{children}</div> }
      </div>
    </div>
  );
};

export default ProjectInfoHeader;